@font-face {
  font-family: 'cf_bold';
  src:  url('./fonts/bold.ttf') format('truetype');
}

@font-face {
  font-family: 'cf_medium';
  src:  url('./fonts/medium.ttf') format('truetype');
}

@font-face {
  font-family: 'cf_light';
  src:  url('./fonts/light.ttf') format('truetype');
}

* {
  padding: 0;
  margin: 0;
}

:root{
  scroll-behavior: smooth;

  --maxWidth: 700px;

  --color1: #343434;
  --color2: rgba(255, 255, 255, 0.9);
  --color3: rgba(255, 255, 255, 0.8);
  --color4: rgba(255,255,255,0.4);
  --color5: rgba(16, 16, 16, 1.0);
  --color6: #e9292f;
  --color7: rgba(52, 52, 52, 1);

  --gap05: 5px;
  --gap1: 10px;
  --gap2: 20px;
  --gap3: 30px;
  --gap4: 50px;
  --padding-big: 50px;


  --confirmBtnLength: 200px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color1);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



p{
  line-height: 150%;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 150%;
}

h1 {
  font-size: 26px;
}

h2 {
  font-size: 20px;
}





/* CONTAINERS UNIVERSAL  */
/* flex / gaps / padding / margin */
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.gap-05 {
  gap: var(--gap05);
}

.gap-1 {
  gap: var(--gap1);
}

.gap-2 {
  gap: var(--gap2);
}

.gap-3 {
  gap: var(--gap3);
}

.gap-4 {
  gap: var(--gap4);
}

.p-1 {
  padding: var(--gap1);
}

.pt-1{
  padding-top: var(--gap1);
}

.pb-1{
  padding-bottom: var(--gap1);
}

.pl-1 {
  padding-left: var(--gap1);
}

.pl-2 {
  padding-left: var(--gap2);
}

.pl-3 {
  padding-left: var(--gap3);
}

.pr-1 {
  padding-right: var(--gap1);
}

.pt-05{
  padding-top: var(--gap05);
}

.pb-05{
  padding-bottom: var(--gap05);
}

.pt-big {
  padding-top: var(--padding-big);
}

.mt-a {
  margin-top: auto;
}

.mt-1 {
  margin-top:  var(--gap1);
}

.mt-2 {
  margin-top:  var(--gap2);
}

.mt-3 {
  margin-top:  var(--gap3);
}

.mt-4 {
  margin-top:  var(--gap4);
}

/*  SIZE  */
.width-fit {
  width: fit-content;
}

.width-full {
  width: 100%;
}

.max-width-800 {
  width: 100%;
  max-width: 800px;
}



/*BUTTONS*/
.chooseBtn {
  padding: var(--gap1);
  border-radius: 5px;
  border: none;
  background: rgba(0,0,0,0.5);
  color: white;
  cursor: pointer;
  margin-bottom: 5px;
}

.sendBtn {
  padding: var(--gap1);
  border-radius: 5px;
  border: none;
  background: var(--color2);
  color: black;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: 600;
}

.vysledkyBtn1 {
  padding: 5px;
  border-radius: 5px;
  border: none;
  background: var(--color2);
  color: black;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}

.vysledkyBtn2 {
  padding: 5px;
  border-radius: 5px;
  border: none;
  background: var(--color4);
  color: black;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  display: flex;
}

.nextBtn1 {
  padding: var(--gap1);
  border-radius: 5px;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  aspect-ratio: 1 / 1;
}

.nextBtn2 {
  padding: var(--gap1);
  border-radius: 5px;
  border: none;
  background: none;
  color: black;
  cursor:not-allowed;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  aspect-ratio: 1 / 1;
  opacity: 0.2;
}

.csvBtn {
  padding: var(--gap05);
  border-radius: 5px;
  border: none;
  background: transparent;
  border: 2px solid var(--color2);
  color: white;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}

@media screen and (min-width: 400px) {
  .vysledkyBtn1 {
    font-size: 14px;
    padding: var(--gap1);
  }

  .vysledkyBtn2 {
    font-size: 14px;
    padding: var(--gap1);
  }
}



/* BORDERS */

.border-bottom-1 {
  border-bottom: 1px solid var(--color4);
  border-bottom-style: dashed;
}

/* BARVY */

.background-1 {
  background: var(--color1);
}

.background-2 {
  background: var(--color2);
}

.background-3 {
  background: var(--color3);
}

.background-4 {
  background: var(--color4);
}

/* texty */
.white-text {
  color: var(--color2);
}

.error {
  color: red;
  font-weight: 600;
}

a{
  text-decoration: none;
  color: initial;
}


/* TEXTY */
.truncate-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* SCROLLING */
.scrollMarginTop-1 {
  scroll-margin-top: var(--gap2);
}

/* CUSTOM  */
.resultsImg {
  width: auto;
  aspect-ratio: 1/1;
  display: none;
}

.nextImg {
  max-width: 15px;
  aspect-ratio: 1 / 1;
}

.flip {
  transform: scaleX(-1);
}

.video-container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
  opacity: 0.1;
}

.video-container video {
  height: 100vh;
  object-fit: cover;
}

@media screen and (min-width: 400px) {
  .resultsImg {
    display: initial;
  }
}


.headerContainer {
  display: flex;
  flex-direction: column;
  gap: var(--gap4);
  margin-top: 50px;
  width: 100%;
}

.headerImg {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  aspect-ratio: 1000 / 1290;
  padding-top: 10px;
}

.thanksImg {
  display: none;
  width: 100%;
  height: auto;
  aspect-ratio: 1000 / 1290;
  padding-top: 10px;
}

.thanksImgMobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  aspect-ratio: 1000 / 1290;
  padding-top: 10px;
}

.top10img {
  width: 100%;
  height: auto;
  aspect-ratio: 1000 / 1290;
}

.pcPad{
  padding-bottom: 20px;
}


@media screen and (min-width: 700px) {

  .headerContainer {
    display: flex;
    flex-direction: row;
    gap: var(--gap2);
    margin-top: 100px;
  }

  .headerImg {
    max-width: 120px;
  }

  .thanksImg {
    display: flex;
    flex-direction: column;
    max-width: 120px;
  }

  .thanksImgMobile {
    display: none;
  }

  .pcPad{
    padding-bottom: 150px;
  }
}



.grad-confirm{
  --cornerEdge: 13px;

  border: none;
  /*width: fit-content;*/
  padding: 15px 30px;
  font-family: "cf_bold";
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  -webkit-clip-path: polygon(var(--cornerEdge) 0, 100% 0, 100% calc(100% - var(--cornerEdge)), calc(100% - var(--cornerEdge)) 100%, 0 100%, 0 var(--cornerEdge));
  clip-path: polygon(var(--cornerEdge) 0, 100% 0, 100% calc(100% - var(--cornerEdge)), calc(100% - var(--cornerEdge)) 100%, 0 100%, 0 var(--cornerEdge));
}



.grad{
  --cornerEdge: 13px;

  border: none;
  /*width: fit-content;*/
  padding: 15px 20px;
  font-family: "cf_bold";
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  -webkit-clip-path: polygon(var(--cornerEdge) 0, 100% 0, 100% calc(100% - var(--cornerEdge)), calc(100% - var(--cornerEdge)) 100%, 0 100%, 0 var(--cornerEdge));
  clip-path: polygon(var(--cornerEdge) 0, 100% 0, 100% calc(100% - var(--cornerEdge)), calc(100% - var(--cornerEdge)) 100%, 0 100%, 0 var(--cornerEdge));
}


.grad2{
    --cornerEdge: 13px;

    border: none;
    /*width: fit-content;*/
    padding: 15px 20px;
    font-family: "cf_bold";
    cursor: pointer;
    font-weight: 600;
    font-size: 11px;
    -webkit-clip-path: polygon(var(--cornerEdge) 0, 100% 0, 100% calc(100% - var(--cornerEdge)), calc(100% - var(--cornerEdge)) 100%, 0 100%, 0 var(--cornerEdge));
    clip-path: polygon(var(--cornerEdge) 0, 100% 0, 100% calc(100% - var(--cornerEdge)), calc(100% - var(--cornerEdge)) 100%, 0 100%, 0 var(--cornerEdge));
}


.red {
  background: var(--color6);
  color: white;
}

.gray {
  background: var(--color7);
  color: white;
}

.white {
  background: white;
  color: black;
}



.ct_light {
  font-family: "cf_light";
}

.ct_medium {
  font-family: "cf_medium";
}

.ct_bold {
  font-family: "cf_bold";
}





.footerContainer {
  display: flex;
  flex-direction: column-reverse;
  gap: 120px;
}


.footerLogoContainer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: 120px;
}

@media screen and (min-width: 800px) {
  .footerContainer {
    display: flex;
    flex-direction: row;
    gap: var(--gap4);
  }

  .footerLogoContainer {
    display: flex;
    flex-direction: column;
  }
}


.footerIcon {
  width: 20px;
  aspect-ratio: 1/1;
  padding: 10px;
  border-radius: 50%;
  background: var(--color6);
}

.linkHover {
  color: white;
}

.linkHover:hover {
  color: var(--color6);
}

input {
  border-radius: 0px;
}

.adminBtn {
  padding: 10px;
  font-weight: 600;
  top: var(--gap2);
  position: absolute;
  right: var(--gap2);
  cursor: pointer;
  background: var(--color6);
  border: none;
  color: white;
  border-radius: 5px;
}


.adminLogin {
  padding: 5px;
}